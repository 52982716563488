import Vue from "vue";
import App from "./App.vue";

Vue.config.productionTip = false;

document.addEventListener("DOMContentLoaded", () => {
  window.vonblon_feedback = new Vue({
    render: (h) => h(App),
  }).$mount();
  document.body.appendChild(window.vonblon_feedback.$el);
  console.log(window.vonblon_feedback);
});
