<template>
  <div class="feedback">
    <h1 class="center">Nehmen Sie mit uns Verbindung auf ...</h1>
    <p>
      Wenn Sie Fragen haben oder etwas bestellen wollen, dann senden Sie uns
      Ihre Nachricht.
    </p>
    <div>
      <label for="name" :class="{ error: !name }">Ihre Namen/Vorname</label>
      <input type="text" v-model="name" />
    </div>
    <div>
      <label for="email" :class="{ error: !email && !telefon }"
        >E-Mail Adresse</label
      >
      <input type="email" v-model="email" />
    </div>
    <div>
      <label for="telefon" :class="{ error: !email && !telefon }"
        >Telefon/Mobil</label
      >
      <input type="telefon" v-model="telefon" />
    </div>
    <div>
      <label for="message" :class="{ error: !message }"
        >Ihre Nachricht / Ihre Frage</label
      >
      <textarea v-model="message" rows="5"></textarea>
    </div>
    <div class="center">
      <button @click="check_and_send" class="send">
        {{ button_title }}
      </button>
    </div>
    <div
      class="message"
      :class="{ success: !response_message, error: response_message }"
      v-if="response_message"
    >
      {{ response_message }}
    </div>
  </div>
</template>

<script>
// const FEEDBACK_URL = "http://localhost:3000/api/v1/feedbacks"
const FEEDBACK_URL = "https://rental.vonblon.cc/api/v1/feedbacks"
import axios from "axios"

// axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*"

export default {
  name: "Feedback",
  data() {
    return {
      origin: location.hostname,
      name: "",
      telefon: "",
      email: "",
      message: "",
      button_title: "Ihre Anfrage senden",
      response_message: "",
    }
  },
  methods: {
    check_and_send() {
      this.response_message = ""
      if (
        this.name == "" ||
        this.message == "" ||
        this.email + this.telefon == ""
      ) {
        this.response_message = "Bitte füllen Sie die Pflichtfelder aus."
        return false
      }
      this.button_title = "..."
      axios
        .post(FEEDBACK_URL, {
          crossDomain: true,
          withCredentials: true,
          feedback: {
            name: this.name,
            email: this.email,
            telefon: this.telefon,
            message: this.message,
            source: window.document.location
          },
        })
        .then((response) => {
          console.log(response)
          this.name = ""
          this.email = ""
          this.telefon = ""
          this.message = ""
          this.response_message =
            "Ihre Daten wurden gespeichert und weitergeleitet"
        })
        .catch((error) => {
          console.log(error)
          this.response_message =
            "Bitte versuchen Sie es später nochmal. Es trat ein Problem auf!"
        })
        .finally(() => {
          this.button_title = "Ihre Antrage senden"
        })
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.message.error,
.message.success {
  margin-top: 5px;
  font-size: 0.9em;
  padding: 4px 5px;
  border: 1px solid darkgray;
  background-color: white;
}
.message.error {
  color: #d02a2a;
}
.success {
  color: white;
}
.feedback {
  padding: 10px;
}
label {
  display: inline-block;
  font-size: 0.9em;
  margin-top: 4px;
}
label.error {
  color: #d02a2a;
}
input,
textarea {
  color: #494c51;
  font-size: 1em;
  width: 95%;
  border: 1px solid #efefef;
  padding: 8px 4px;
}
.right {
  text-align: end;
}
.center {
  text-align: center;
}
button.send {
  cursor: pointer;
  margin-top: 6px;
  border: 1px solid transparent;
  padding: 10px 20px;
  background-color: red;
  color: white;
  font-size: 0.9em;
  border-radius: 5px;
}
h1 {
  font-size: 1.4em;
  line-height: 1.4em;
  font-weight: 300;
}
</style>
