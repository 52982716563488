<template>
  <div id="app" :class="{ is_open: feedback_visible }">
    <div class="logo">
      <h2 class="title">Anfrage</h2>
      <img
        @click="toggle_feedback"
        alt="Vonblon Feeback"
        src="https://rental.vonblon.cc/feedback/logo.svg"
      />
      <span v-if="feedback_visible">Schliessen</span>
    </div>
    <feedback v-if="feedback_visible" />
  </div>
</template>

<script>
import Feedback from "./components/Feedback.vue"

export default {
  name: "App",
  data() {
    return {
      feedback_visible: false,
    }
  },
  methods: {
    toggle_feedback() {
      this.feedback_visible = !this.feedback_visible
    },
  },
  components: { Feedback },
}
</script>

<style scoped>
#app {
  z-index: 1010;
  display: flex;
  flex-flow: column;
  position: fixed;
  right: 10px;
  bottom: 10px;
  padding: 5px;
  align-content: center;
  align-items: center;
  justify-content: center;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
#app h2.title {
  background-color: white;
  border: 2px solid black;
  padding: 3px 6px;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 2px;
}
#app.is_open h2.title {
  display: none;
}
#app img {
  width: 80px;
  cursor: pointer;
  /* box-shadow: 0 0 10px white; */
}
#app.is_open img {
  box-shadow: unset;
}
#app.is_open {
  /* background-color: rgba(0, 128, 0, 0.4); */
  background-color: #7d7d7d;
  color: white;
  width: 250px;
  border-radius: 15px;
  padding-top: 15px;
}
#app:not(.is_open) img {
  /* -webkit-animation: rotate-scale-up 1.65s linear both infinite; */
  animation: rotate-scale-up 5s linear both infinite;
}
.logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.8rem;
}
@-webkit-keyframes rotate-scale-up {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  10% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  20% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes rotate-scale-up {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  10% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  20% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
</style>
